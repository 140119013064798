import { useRef } from 'react';
import classNames from 'classnames';
import './index.css';
import { Tooltip } from '../Tooltip';

type CheckboxProps = {
    value?: boolean,
    name: string;
    handler?: () => void;
    disabled: boolean;
    checked: boolean | null;
    helpText?: string;
};

const Checkbox = ({ value, name, handler, checked, disabled = false, helpText }: CheckboxProps) => {
    const ref = useRef<HTMLInputElement>(null);

    // Обработчик клика, который проверяет, не отключен ли элемент
    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (disabled) return; // Если checkbox отключен, не выполняем дальнейшую логику

        if (ref && ref.current) {
            ref.current.checked = !ref.current.checked;
        }
        if (handler) {
            handler();
        }
    };

    return (
        <label
            className={classNames('UI-Checkbox', {
                'UI-Checkbox--disabled': disabled,
            })}
            onClick={onClick}
        >
            <input
                ref={ref}
                className="UI-Checkbox__input"
                type="checkbox"
                checked={value}
                defaultChecked={value === undefined ? undefined : value}
                name={name}
                disabled={disabled}
                readOnly={value !== undefined} // Блокируем ручное изменение если чекбокс управляемый
            />
            <span className="UI-Checkbox__fake-box"></span>
            <span className="UI-Checkbox__wrap-label">
                <span className="UI-Checkbox__label-text">{name}</span>
                {helpText && <Tooltip text={helpText}/>}
            </span>
        </label>
    );
};

export {Checkbox};
