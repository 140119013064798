import React, { ForwardedRef } from 'react';
import ReactSelect from 'react-select';
import './index.css';
import { Tooltip } from '../Tooltip';

type Option = { value: string; label: string };

type SelectProps = {
    name: string;
    label: string;
    options: Option[];
    handleOnChange: ({ label, value }: { label: string; value: string }) => void;
    helpText?: string;
    value: { label: string; value: string } | null;
};

const Select = React.forwardRef(
    ({ name, label, options, handleOnChange, helpText, value }: SelectProps, ref: ForwardedRef<HTMLSelectElement | null>) => (
        <div className="UI-Select">
            <label className="UI-Select__label">
                <span className="UI-Select__field-name">
                    {label}
                    {helpText && <Tooltip text={helpText} />}
                </span>
                <ReactSelect
                    required={true}
                    name={name}
                    options={options}
                    value={value}
                    onChange={(newValue) => {
                        if (newValue) {
                            handleOnChange({ label: newValue.label, value: newValue.value });
                        }
                    }}
                    styles={{
                        control: (styles) => ({
                            ...styles,
                            height: '55px',
                            color: 'white',
                            borderColor: 'transparent',
                            borderRadius: '5px',
                            backgroundColor: 'var(--color-gray-10)',
                            boxShadow: 'none',
                            ':hover': {
                                ...styles[':active'],
                                borderColor: 'var(--color-teal)',
                            },
                        }),
                        singleValue: (styles) => ({
                            ...styles,
                            color: 'white',
                        }),
                        menu: (styles) => ({
                            ...styles,
                            backgroundColor: 'var(--color-gray-10)',
                        }),
                        option: (styles) => ({
                            ...styles,
                            backgroundColor: 'transparent !important',
                            ':hover': {
                                ...styles[':active'],
                                color: 'var(--color-teal)',
                                backgroundColor: 'transparent !important',
                            },
                        }),
                    }}
                />
            </label>
        </div>
    )
);

export { Select };