import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { AdvancedUploadRequest, UploadRequest, UploadResponse } from 'features/Upload/data';

export let host = '';

if (process.env.NODE_ENV === 'development') {
    host = 'https://dnalm.airi.net';
}

function isAdvancedUploadRequest(
    uploadData: UploadRequest | AdvancedUploadRequest
): uploadData is AdvancedUploadRequest {
    return (uploadData as AdvancedUploadRequest).dna_1 !== undefined;
}

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery: fetchBaseQuery({
        baseUrl: host,
    }),
    endpoints: (builder) => ({
        upload: builder.mutation<UploadResponse, UploadRequest | AdvancedUploadRequest>({
            query: (uploadData: UploadRequest | AdvancedUploadRequest) => {
                const data = new FormData();

                // Проверка типа, если это AdvancedUploadRequest
                if (isAdvancedUploadRequest(uploadData)) {
                    // Для AdvancedUploadRequest
                    data.append('dna_1', uploadData.dna_1);
                    data.append('dna_2', uploadData.dna_2);
                    if (uploadData.file_1) {
                        data.append('file_1', uploadData.file_1);
                    }
                    if (uploadData.file_2) {
                        data.append('file_2', uploadData.file_2);
                    }
                } else {
                    // Для UploadRequest
                    data.append('dna', uploadData.dna);
                    if (uploadData.file) {
                        data.append('file', uploadData.file);
                    }
                }

                data.append('model', uploadData.model);
                data.append('id', uploadData.id);
                data.append('importance', `${uploadData.importance}`);

                return {
                    url: `/api/${uploadData.model}/upload`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        checkUpload: builder.query({
            query: ({ model, id }: { model: string; id: string | null }) => {
                if (model.includes('dnabert')) {
                    return {
                        url: `/generated/${model}/DNABERT_storage/request_${id}/progress.json`,
                    };
                }
                return {
                    url: `/generated/${model}/${id}_progress.json`,
                };
            },
        }),
    }),
});

export const { useUploadMutation, useCheckUploadQuery } = appApi;

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    return (
        typeof error === 'object' &&
        error != null &&
        'status' in error &&
        'data' in error &&
        typeof error.data === 'object' &&
        error.data != null &&
        !('message' in error.data)
    );
}

export function isErrorWithMessage(
    error: unknown
): error is { data: { message: string; status: string }; status: string } {
    return (
        typeof error === 'object' &&
        error != null &&
        'data' in error &&
        typeof error.data === 'object' &&
        error.data != null &&
        'message' in error.data &&
        typeof error.data.message === 'string'
    );
}
