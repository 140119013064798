import { useEffect, useState } from 'react';
import * as igv from 'igv';
import './index.css';
import { Icon } from '../../shared/ui/Icon';
import { DataDescriptions, DataGeneral } from './data';
import { Button } from '../../shared/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { appApi, host, useUploadMutation } from '../../app/api';
import { resetState } from '../../app/slice';
import { Link } from 'react-router-dom';

const Genome = () => {
    const dispatch = useDispatch();
    const resetData = () => {
        dispatch(resetState());
        dispatch(appApi.util.resetApiState());
    };

    const { model, importance } = useSelector((state: RootState) => state.app);
    const [_, { isSuccess: isUploadSuccess, data: dataUpload }] = useUploadMutation({
        fixedCacheKey: 'shared-upload',
    });
    const [isShowText, setIsShowText] = useState(false);
    const requestId = dataUpload?.archive.split('/')[3].replace('_archive.zip', '');

    useEffect(() => {
        if (isUploadSuccess && dataUpload) {
            const igvDiv = document.getElementById('igv-div');
            const tracks = [];

            if (dataUpload?.acceptor_bed_file) {
                tracks.push({
                    name: 'acceptor',
                    url: `${host}${dataUpload?.acceptor_bed_file}`,
                });
            }

            if (dataUpload?.bed.length > 0) {
                dataUpload?.bed.forEach((bedFile: any) => {
                    tracks.push({
                        url: `${host}${bedFile}`,
                    });
                });
            }

            const options = {
                reference: {
                    fastaURL: `${host}${dataUpload?.fasta_file}`,
                    indexURL: `${host}${dataUpload?.fai_file}`,
                    tracks: [...tracks],
                },
            };

            igv.createBrowser(igvDiv, options).then((browser: any) => {
                if (importance) {
                    let currentImportanceFeature = '';
                    browser.on('trackclick', (track: any, popoverData: any) => {
                        let symbol = null;

                        if (track.config.format === 'bedgraph') {
                            popoverData.forEach((nameValue: any) => {
                                if (nameValue.name?.toLowerCase() === 'position:') {
                                    const [start, end] = nameValue.value.split('-');
                                    symbol = `${track._name}_${start}_${end}`;
                                }
                            });
                        } else {
                            // Обработка других форматов (например, bed)
                            for (let i = 0; i < popoverData.length; i++) {
                                const nameValue = popoverData[i];

                                if (nameValue.name?.toLowerCase() === 'name') {
                                    // Если есть 'Name', используем его для символа
                                    symbol = nameValue.value;
                                    break; // Прерываем цикл
                                }

                                if (nameValue.name?.toLowerCase() === 'location') {
                                    const locationValue = nameValue.value.split(':')[1]; // Пример: "dm6_dna:6,001-8,000"
                                    if (locationValue) {
                                        const [start, end] = locationValue.split('-');
                                        symbol = `${track._name}_${start.replace(',', '')}_${end.replace(',', '')}`;
                                    }
                                }
                            }
                        }

                        console.log('symbol: ' + symbol);
                        if (symbol && currentImportanceFeature != symbol) {
                            console.log('Updating importance track');

                            // if there is previously loaded track, remove it
                            if (currentImportanceFeature != '') {
                                console.log('removing track ' + currentImportanceFeature);
                                browser.removeTrackByName(currentImportanceFeature);
                            }

                            currentImportanceFeature = `Importance ${symbol}`;

                            // load feature importance track
                            const trackName = './' + symbol + '.bedGraph';
                            const trackUrl = `${host}/generated/${
                                model?.value
                            }/${requestId}_attributions_${trackName.slice(2)}`;
                            console.log('track_name: ' + trackName);
                            const configTrack = {
                                url: trackUrl,
                                format: 'wig',
                                name: `Importance ${symbol}`,
                            };
                            console.log(browser.loadTrack(configTrack));
                        }

                        // Continue with the default pop-over behavior
                        return popoverData;
                    });
                }
            });
        }
    }, [isUploadSuccess, dataUpload]);

    return (
        <>
            <div className="container">
                <div className="slider">
                    <div onClick={() => setIsShowText((prevState) => !prevState)} className="slider__header">
                        <div className="slider__title">
                            <Icon name={'question'} />
                            Help
                        </div>
                        {isShowText ? <Icon name={'arrow-top'} /> : <Icon name={'arrow-down'} />}
                    </div>
                    {isShowText && (
                        <div className="slider__content">
                            <div>{model && DataDescriptions[model.value]}</div>
                            {DataGeneral}
                        </div>
                    )}
                </div>
                <div className="F-Genome__archive">
                    <Button type={'link'} href={dataUpload && dataUpload?.archive}>
                        Download results
                    </Button>
                </div>
                <div className="F-Genome__new-job">
                    <Link onClick={resetData} to="/" className="F-Genome__new-job-link">
                        New job
                    </Link>
                </div>
            </div>
            <div className="F-Genome" id="igv-div" />
        </>
    );
};

export { Genome };
