import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { appApi } from './api';

type TInitialState = {
    statusApp: 'INIT' | 'PROCESSING' | 'FINISH';
    model: { label: string; value: string } | null;
    skip: boolean;
    idUploadFile: string | null;
    importance: boolean | null;
};

const initialState: TInitialState = {
    statusApp: 'INIT',
    model: null,
    skip: false,
    idUploadFile: null,
    importance: false,
};

export const appSlice = createSlice({
    name: 'App',
    initialState: initialState,
    reducers: {
        setModel: (state, action: PayloadAction<{ label: string; value: string } | null>) => {
            state.model = action.payload;
        },
        setIdUploadFile: (state, action: PayloadAction<{ id: string | null }>) => {
            state.idUploadFile = action.payload.id;
        },
        resetState: (state) => {
            return initialState;
        },
        setImportance: (state, action: PayloadAction<{ value: null | boolean }>) => {
            if (action.payload.value === null) {
                state.importance = !state.importance;
            } else {
                state.importance = action.payload.value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(appApi.endpoints.upload.matchFulfilled, (state, { meta }) => {
            state.statusApp = 'FINISH';
        });
    },
});

export const { setModel, resetState, setIdUploadFile, setImportance } = appSlice.actions;

export default appSlice.reducer;
